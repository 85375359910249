import componentI18n from '@/i18n/componentI18n';
import router from '@/router';
import { mapActions } from 'vuex';
import { defineComponent } from 'vue';
import constants from '@/constants';
export default defineComponent({
    name: 'UserPasswordForm',
    ...componentI18n('components.user_password_form'),
    data() {
        return {
            form: {
                error: {
                    newPassword: '',
                    newPasswordConfirmation: '',
                    password: ''
                },
                newPassword: '',
                newPasswordConfirmation: '',
                password: '',
                updated: false
            }
        };
    },
    computed: {
        valid() {
            return this.form.newPassword && this.form.newPasswordConfirmation && this.form.password;
        }
    },
    methods: {
        ...mapActions(['updateUser']),
        checkPasswordErrors() {
            let valid = true;
            if (this.form.newPassword.length < constants.PASSWORD_MINIMUM_LENGTH) {
                this.form.error.newPassword = 'password_should_be_longer';
                valid = false;
            }
            else {
                this.form.error.newPassword = '';
            }
            if (this.form.newPassword !== this.form.newPasswordConfirmation) {
                this.form.error.newPasswordConfirmation = 'passwords_dont_match';
                valid = false;
            }
            else {
                this.form.error.newPasswordConfirmation = '';
            }
            return valid;
        },
        async updatePassword() {
            if (this.checkPasswordErrors()) {
                const { success, message } = await this.updateUser({
                    password: this.form.password,
                    newPassword: this.form.newPassword
                });
                if (message === 'already_in_use') {
                    this.form.error.newPassword = 'password_already_in_use';
                }
                else if (success) {
                    this.form.updated = true;
                    router.push({ name: 'profile' });
                }
            }
        }
    }
});
